// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-page .hero-banner {
  width: 100%;
}
.privacy-page .content {
  padding: 64px 277px;
}
@media screen and (max-width: 1024px) {
  .privacy-page .content {
    padding: 64px 24px;
  }
}
.privacy-page .content h3 {
  margin-bottom: 64px;
}
.privacy-page .content ol {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.privacy-page .content ol li p {
  margin: 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyPage/PrivacyPage.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEQ;EAHJ;IAIQ,kBAAA;EACV;AACF;AACQ;EAAK,mBAAA;AAEb;AAAQ;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAEZ;AACgB;EAAG,cAAA;AAEnB","sourcesContent":[".privacy-page {\n    .hero-banner {\n        width: 100%;\n    }\n    .content {\n        padding: 64px 277px;\n\n        @media screen and (max-width: 1024px) {\n            padding: 64px 24px; \n        }\n\n        h3 { margin-bottom: 64px; }\n\n        ol {\n            display: flex;\n            flex-direction: column;\n            gap: 32px;\n\n            li {\n                p {margin: 16px 0;}\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
