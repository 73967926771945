// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conditions-page .hero-banner {
  width: 100%;
}
.conditions-page .content {
  padding: 64px 277px;
}
.conditions-page .content h3 {
  margin-bottom: 64px;
}
.conditions-page .content ol {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.conditions-page .content ol li .sucursales-page .content p {
  margin: 16px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/ConditionsPage/ConditionsPage.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AAEI;EACI,mBAAA;AAAR;AAEQ;EAAK,mBAAA;AACb;AACQ;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AACZ;AACgB;EACG,cAAA;AACnB","sourcesContent":[".conditions-page {\n    .hero-banner {\n        width: 100%;\n    }\n    .content {\n        padding: 64px 277px;\n\n        h3 { margin-bottom: 64px; }\n\n        ol {\n            display: flex;\n            flex-direction: column;\n            gap: 32px;\n\n            li {.sucursales-page .content\n                p {margin: 16px 0;}\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
